var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('v-container',{staticClass:"mb-2"},[_c('h2',[_vm._v("Custom Staff Survey Question Library")]),_c('p',[_vm._v("You can include up to 5 questions from the list below in your staff survey. Any optional questions you’ve selected can be removed at any time. Removing a question does not remove responses already received. ")]),_c('p',[_vm._v("The question library offers optional questions suggested by users of the Digital Maturity Assessment and curated by the Assessment team. ")]),_c('p',[_vm._v(" Can’t find the right question? You can suggest a new question for the library "),_c('v-dialog',{attrs:{"max-width":"900px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({},on),[_vm._v("here.")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('new-question',{on:{"close":function($event){_vm.dialog = false}}})],1)],1),_c('v-data-table',{attrs:{"items":_vm.questions,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.approved)?_c('v-chip',{attrs:{"color":"success"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-check-circle")]),_vm._v(" Available")],1):_c('v-chip',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-timer-sand")]),_vm._v(" Pending Review")],1)]}},{key:"item.answerOptions",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(_vm._s(item.answerOptions))])]}},{key:"item.visibility",fn:function(ref){
var item = ref.item;
return [_c('ul',[_vm._l((item.jobFunctions),function(i){return _c('li',{key:i},[_vm._v(" "+_vm._s(_vm.categoryOptions[i].name)+" ")])}),_vm._l((item.services),function(i){return _c('li',{key:i},[_vm._v(" "+_vm._s(_vm.categoryOptions[i].name)+" ")])})],2),(item.approved)?_c('span'):_vm._e()]}},{key:"item.btn",fn:function(ref){
var item = ref.item;
return [(item.approved)?_c('span',[(item.organisations.includes(_vm.organisation))?_c('span',[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.removeQ(item)}}},[_vm._v("Remove")])],1):_c('span',[_c('v-btn',{attrs:{"color":"success","disabled":_vm.disableNew},on:{"click":function($event){return _vm.addQ(item)}}},[_vm._v("Add")])],1)]):_vm._e()]}}])}),(_vm.disableNew)?_c('v-alert',{attrs:{"type":"error","text":""}},[_vm._v(" A maximum of 5 questions allowed. Please remove a question before adding a new one. ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }