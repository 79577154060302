<template>
    <v-card flat>
        <v-form 
        ref="form" 
        v-model="valid"
        >    
            <v-card-title>Request New Question</v-card-title>
            <v-card-text>
                <p>
                    You can use this form to request a new question if you can’t see a question you’d like to include in the question library.
                </p>
                <p>
                    Your suggestion does not need to include perfect wording or question structure - when you submit the form, the assessment team will review your request; they may get back to you with any clarifications as part of their review.
                </p>
                <p>
                    However please consider the following when requesting vis this form:
                </p>
                <ul class="mb-3">
                    <li>Questions must generate data that is generically useful – not just for you or your organisation</li>
                    <li>Questions must be framed so other organisations can reuse them</li>
                    <li>Questions must align with the scheme used throughout the assessment (E.g., ask agreement with a statement describing what good looks like).</li>
                    <li>Questions must not ask about more than one thing (compound question), may not be leading, and must be within the scope of the knowledge of individuals responding to the staff survey.</li>
                </ul>
                <p>
                    If you would like assistance with your question please feel free to contact us on <a hrf="mailto:sg@dma.works">sg@dma.works</a>. 
                </p>

                <v-text-field 
                v-model="text" 
                outlined 
                hint="Question test should be formulated as a statement of the positive, e.g., “Your organisation provides enough laptops for you work efficiently"
                rounded 
                :rules="required" 
                persistent-hint
                label="Question Text">
                </v-text-field>
                <v-textarea  
                v-model="tooltip" 
                outlined 
                persistent-hint
                rounded 
                hint="Tooltip should elaborate on what the best possible answer option might look like, e.g. “Agree Completely” means a) Staff do not have to wait to access laptops b) Laptop specification is sufficient for staff to work efficiently c) Laptop availability and suitability is reviewed regularly"
                :rules="required" 
                label="Tooltip Content">
                </v-textarea>
                <v-select 
                outlined 
                persistent-hint
                hint="Agreement scales should be given preference wherever possible"
                rounded 
                :rules="required"
                v-model="answerOptions" :items="answerOptionsOptions" label="Answer Options">
                </v-select>
                <v-select 
                outlined 
                rounded 
                v-model="jobFunctions" 
                item-text="name"
                persistent-hint
                hint="Only limit the visibility of your question to specific roles if it is out of the scope of respondents’  knowledge elsewhere. If you’re unsure, leave this section blank."
                item-value="id"
                multiple 
                :items="jobFunctionOptions" 
                label="Display for Job Functions"></v-select>
                <v-select 
                outlined 
                rounded
                hint="Only limit the visibility of your question to specific services if it is out of the scope of respondents’  knowledge elsewhere. If you’re unsure, leave this section blank." 
                v-model="services" 
                item-text="name"
                item-value="id"
                multiple 
                :items="serviceOptions" 
                label="Display for Services"></v-select>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="reset">Clear</v-btn>
                <v-btn :disabled="!valid" color="success" @click="submit">Submit</v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>


<script>


export default {
    name: "NewQuestion",
    data: () => {
        return {
            valid: null,
            text: null, 
            tooltip: null,
            answerOptions: null,
            services: [], 
            jobFunctions: [],
            answerOptionsOptions: [
                {text: "Likert Scale (Disagree Completely - Agree Completely)", value: "likert"},
                {text: "Percent Scale (0% - 100%)", value: "percent"},
            ],
            required: [
                v => !!v || 'Required'
            ],
        }
    }, 
    computed: {
		organisation() {
			return this.$store.state.organisation.data
		},
		categoryOptions() {
			return this.$store.state.categoryOptions.data;
		},		
		providerType() {
			const id = this.organisation.provider[0];
			if( !id ){
				return null;
			}
			return this.$store.state.categoryOptions.data[id]
		},
		serviceOptions() {
            const self = this;
			let options = this.providerType ? this.providerType.services : []
			return options.map( option => self.categoryOptions[option])
		},
        jobFunctionOptions() {
			return this.$store.getters["jobFunctions/options"];
		},
    }, 
    methods: {
        reset() {
            this.$refs.form.reset();
        },
        submit(){
            let question = {
                text: this.text, 
                tooltip: this.tooltip,
                answerOptions: this.answerOptions,
                services: this.services,
                jobFunctions: this.jobFunctions,
            }
            this.$store.dispatch("staffSurveyQuestions/insert", question).then( () => {
                this.reset();
                this.$emit("close");
            })
        }
    }
}
</script>