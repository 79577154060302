<template>
	<v-sheet>
		<v-container class="mb-2">
			<h2>Custom Staff Survey Question Library</h2>
			<p>You can include up to 5 questions from the list below in your staff survey. Any optional questions you’ve selected can be removed at any time. Removing a question does not remove responses already received. </p>
			<p>The question library offers optional questions suggested by users of the Digital Maturity Assessment and curated by the Assessment team. </p>
			<p> Can’t find the right question? You can suggest a new question for the library 
				<v-dialog v-model="dialog" max-width="900px">
					<template v-slot:activator="{ on }">
						<a v-on="on">here.</a>
					</template>
					<new-question @close="dialog = false"></new-question>
				</v-dialog>
			</p>
			<v-data-table :items="questions" :headers="headers">
				<template v-slot:item.status="{ item }">					
					<v-chip v-if="item.approved" color="success"><v-icon class="mr-2">mdi-check-circle</v-icon>
						Available</v-chip>
					<v-chip v-else><v-icon class="mr-2">mdi-timer-sand</v-icon>
					Pending Review</v-chip>
				</template>
				<template v-slot:item.answerOptions="{ item }">
					<v-chip color="primary">{{item.answerOptions}}</v-chip>
				</template>
				<template v-slot:item.visibility="{ item }">
					<ul>
						<li v-for="i in item.jobFunctions" :key="i">
							{{categoryOptions[i].name }}
						</li>
						<li v-for="i in item.services" :key="i">
							{{categoryOptions[i].name }}
						</li>
					</ul>
					<span v-if="item.approved"></span>
				</template>
				<template v-slot:item.btn="{ item }">
					<span v-if="item.approved">
						<span v-if="item.organisations.includes(organisation)">
							<v-btn color="error" @click="removeQ(item)">Remove</v-btn>
						</span>
						<span v-else>
							<v-btn color="success" :disabled="disableNew" @click="addQ(item)">Add</v-btn>
						</span>
					</span>
				</template>
			</v-data-table>
			<v-alert type="error" text v-if="disableNew">
				A maximum of 5 questions allowed. Please remove a question before adding a new one.
			</v-alert>
		</v-container>
	</v-sheet>
</template>

<script>
	import { arrayUnion, arrayRemove } from "vuex-easy-firestore";
	import NewQuestion from '@c/staff-survey-questions/NewQuestion.vue';
	export default {
		name: "StaffSurveyQuestions",
		data: () => {
			return {
				dialog: false,
				headers: [
					{text: "Status", value: "status" },
					{text: "Question Text", value: "text" },
					{text: "Tooltip", value: "tooltip" },
					{text: "Answer Options", value: "answerOptions" },
					{text: "Visible To", value: "visibility" },
					{text: " ", value: "btn" },
				]
			};
		},
		computed: {
			categoryOptions(){
				return this.$store.state.categoryOptions.data
			},
			organisation() {
				return this.$store.state.organisation.data.id;
			},
			questions() {
				return this.$store.getters["staffSurveyQuestions/available"];
			},
			disableNew(){
				const self = this;
				return self.questions.filter( q => q.organisations.includes(self.organisation) ).length == 5
			}
		},
		methods: {
			addQ(question){
				this.$store
				.dispatch("staffSurveyQuestions/patch", {
					id: question.id,
					organisations: arrayUnion(this.organisation),
				}).then(() => {
					this.$root.$emit("showToast", "Saved");
				}).catch( e => console.log(e) )
			},
			removeQ(question){
				this.$store
				.dispatch("staffSurveyQuestions/patch", {
					id: question.id,
					organisations: arrayRemove(this.organisation),
				}).then(() => {
					this.$root.$emit("showToast", "Saved");
				}).catch( e => console.log(e) )
			},
			
		},
		components: {
			NewQuestion
		},
		created(){
NewQuestion
			this.$store.dispatch("jobFunctions/openDBChannel");
			this.$store.dispatch("staffSurveyQuestions/openDBChannel")
		}
	};
</script>
